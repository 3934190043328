import { ReactComponent as Logo } from "./assets/logo-white.svg";

const TermsAndConditions = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-start overflow-x-hidden overflow-y-auto">
      <div className="w-full h-14 bg-black text-white flex flex-row justify-center items-center">
        <div className="w-full h-14 flex flex-row justify-between items-center max-w-[1440px]">
          <div
            className="flex flex-row justify-start items-center h-full gap-3 cursor-pointer"
            onClick={() => (window.location.href = "https://augustamuseum.org")}
          >
            <Logo className="h-3/5 w-auto" />
            <h1 className="font-serif font-light text-[18px]">
              Augusta Museum of History
            </h1>
          </div>
          <div className="flex flex-row justify-end items-center gap-3 uppercase font-body font-semibold text-sm">
            <h1
              className="text-white hover:text-blue-700 transition-all duration-150 ease-in-out cursor-pointer"
              onClick={() =>
                (window.location.href = "https://augustamuseum.org")
              }
            >
              Home
            </h1>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-1 py-16 flex-col justify-start items-center">
        <div className="w-full h-full max-w-[1440px] flex flex-col justify-start items-start gap-5">
          <h1 className="font-medium text-4xl">Terms & Conditions</h1>
          <p>
            By purchasing a ticket, you the (“customer”) agree to be bound by
            the Terms and Conditions of sale specified herein and any other
            provision as may be specified from time to time by The Augusta
            Museum of History Inc. The Augusta Museum of History Inc, their
            agents or employees shall not be liable for any personal injury,
            loss, damage or death caused or occurring while in the venue,
            location or place related to The Augusta Museum of History Inc
            (“site” or “experience” or “event”).
          </p>
          <p>Bookings are made and sold subject to following conditions:</p>
          <ol className="list-decimal px-10">
            <li className="py-2">
              You shall not enter the site under the influence of drugs or
              alcohol. Failing this The Augusta Museum of History Inc, and any
              employee or agent of The Augusta Museum of History Inc, shall
              reserve all rights and power to decline or refuse your entry to
              the Site. If you are found to be under such influence, The Augusta
              Museum of History Inc, their employee, or agent shall have the
              power, authority and consent expressly granted by you to take the
              necessary legal action to ensure reputation of the business remain
              intact and guard against any risk or harm towards the site, The
              Augusta Museum of History Inc brand and business, employees, or
              other customers. All losses, damages or legal costs derived from
              such incident shall be solely borne by you.
            </li>
            <li className="py-2">
              You agree not to carry or bring any dangerous, harmful or
              hazardous object on/in to the site including, but not limited to,
              sharp objects, explosive items, weapons or item deemed to be
              dangerous or harmful to other visitors on the site. In the event
              that such item or object is found, The Augusta Museum of History
              Inc, their employee or agent shall have the power, authority and
              consent expressly granted by you to decline or refuse your entry
              to the site/event.
            </li>
            <li className="py-2">
              You agree to behave, conduct, perform, carry out, execute, achieve
              and complete the experience/event in accordance to the
              instruction, command and rules specified by The Augusta Museum of
              History Inc, failing which The Augusta Museum of History Inc,
              their employees, or agent shall have the right and power to
              decline or refuse your entry to the building.
            </li>
            <li className="py-2">
              All items including, but not limited to, your personal belongings,
              objects, luggage, baggage, watches, wallets, items and/or
              property, shall be kept by you at your sole risk. In any event,
              The Augusta Museum of History Inc, their employees, or agent shall
              not be held liable or responsible for any loss of your personal
              belongings within the site or outside of the site. The risk of
              loss for your personal belongings shall be borne by you upon
              entrance on the site or engagement with The Augusta Museum of
              History Inc business.
            </li>
            <li className="py-2">
              You shall not be allowed to record, capture or snap any
              photograph, video, film, tape or audio recording whatsoever
              before, during and after the experience/event, except with the
              explicit permission of The Augusta Museum of History Inc. If such
              recording activity is found within the site, The Augusta{" "}
            </li>
            <li className="py-2">
              Museum of History Inc, their employee, or agent shall reserve the
              power, authority and consent expressly granted by you to delete,
              remove or transfer such recording to any device, media or hardware
              owned by The Augusta Museum of History Inc, the copyright of such
              recordings will be deemed automatically granted by you to The
              Augusta Museum of History Inc. To the fullest extent permissible
              by applicable law; The Augusta Museum of History Inc, their the
              employees or agent shall not be responsible for any risk, hazard,
              danger, security, threat, safety and/or protection for the
              experience/event. You shall be solely responsible, answerable and
              accountable for your personal safety, security, body condition,
              disease transmission, pregnancy or health condition, while you
              participate, join, enter, use, participate in and/or access the
              site/experience/events. The Augusta Museum of History Inc hereby
              expressly warn, caution, advise, inform and/or notify that the
              experience/event may be dangerous, hazardous, risky, and unsafe,
              insecure to you and you shall be duly informed, notified and
              understand the underlying danger, risk or hazard of the
              experience/event and shall further confirmed, ensure and guard
              against your body condition and personal safety for the
              experience/event.
            </li>
          </ol>
          <p>
            All bookings are final and cannot be changed. There are absolutely
            no refunds under any conditions, including but not limited to
            personal injury, illness, weather or traffic conditions and no
            refund will be provided in case of booking cancellation or no-show.
            You are purchasing a fixed time and date regardless of if some or
            all of your group uses that time. However, the Client reserves the
            right to transfer his purchased ticket to another person in the
            event of Client not being able to attend. You must arrive 15 minutes
            prior to the scheduled experience/event start to allow time for
            parking, use of restrooms, introduction, etc. If you arrive late and
            other participants have arrived, then the experience/event will
            start on time, and you may join the experience/event later for the
            remaining time in the experience/event.
          </p>
          <p>
            Participants of all ages are welcome. Our experience/events are
            designed for participant ages 13 and over. Participants under 16
            years of age must be accompanied by at least one adult over 18 years
            old to participate in any experience/event. Furthermore, at least
            one adult parent or guardian aged 18 or more, must be present during
            check-in; this parent or guardian is not required to participate in
            the experience/event unless any participants in the group are under
            16 years old.
          </p>
          <p>
            In the event of a technical issue which results in a double booking
            of any experience/event, or for event outside of our control, we
            reserve the right to cancel or move any reservation at our sole
            discretion.
          </p>
          <p>
            Intoxication and behavior policy: For the safety of all guests and
            our staff, anyone who is or appears to be intoxicated at the sole
            discretion of our staff, will not be allowed to enter any exhibit
            and no exchanges or refunds will be awarded. Any player who behaves
            in an unruly way, including but not limiting to, shouting, swearing,
            threatening other participants, climbing on furniture, throwing or
            damaging Museum items, forcing or using force to open locks will be
            removed from the experience/event and no refunds will be given. All
            participants accept without limitation that they are financially
            responsible to The Augusta Museum of History Inc for any damage they
            cause to the Museum items, including but not limited to locks,
            experience/event props, decorations, furniture, exhibit items and
            accept they will be required to reimburse The Augusta Museum of
            History Inc for the full cost of any repair or replacement.
          </p>
          <p>
            None of these terms and conditions may be amended or adjusted
            verbally by any employee, and any change or modification can only be
            agreed in writing.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
