import axiosInstance from "./axios";

export const GetEvents = () => {
  return axiosInstance.get("/events");
};

export const CreatePaymentIntent = (data) => {
  return axiosInstance.post("/events/paymentIntent", data);
};

export const CheckPayment = (data) => {
  return axiosInstance.post("/events/checkPayment", data);
};
