import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CheckPayment } from "./actions/events";
import { ReactComponent as Logo } from "./assets/logo-white.svg";
import dayjs from "dayjs";

const Success = () => {
  const [searchParams] = useSearchParams();
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let inView = true;
    if (inView) {
      let pi = searchParams.get("payment_intent");
      if (pi && pi.length > 0) {
        CheckPayment({ paymentIntent: pi })
          .then((res) => {
            setPaymentIntent(res.data);
            setTimeout(() => {
              setLoading(false);
            }, 700);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const formatter = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price);
  };

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-start">
      <div className="w-full h-14 bg-black text-white flex flex-row justify-center items-center">
        <div className="w-full h-14 flex flex-row justify-between items-center max-w-[1440px]">
          <div
            className="flex flex-row justify-start items-center h-full gap-3 cursor-pointer"
            onClick={() => (window.location.href = "https://augustamuseum.org")}
          >
            <Logo className="h-3/5 w-auto" />
            <h1 className="font-serif font-light text-[18px]">
              Augusta Museum of History
            </h1>
          </div>
          <div className="flex flex-row justify-end items-center gap-3 uppercase font-body font-semibold text-sm">
            <h1
              className="text-white hover:text-blue-700 transition-all duration-150 ease-in-out cursor-pointer"
              onClick={() =>
                (window.location.href = "https://augustamuseum.org")
              }
            >
              Home
            </h1>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-1 py-16 flex-col justify-center items-center">
        <div className="w-full h-full max-w-[1440px] flex flex-col justify-center items-center gap-5">
          <div className="py-6 px-36 bg-slate-200 flex flex-col justify-start items-center gap-2 max-w-3xl">
            {loading ? (
              <h1 className="text-2xl font-bold font-serif tracking-wider uppercase">
                Loading...
              </h1>
            ) : (
              <>
                <h1 className="text-2xl font-bold font-serif tracking-wider uppercase">
                  {paymentIntent.status === "succeeded"
                    ? "Purchase Successful"
                    : "Purchase Invalid"}
                </h1>
                <div className="w-full border-t border-slate-500 my-3" />
                {paymentIntent.events.map((event) => (
                  <EventBlock
                    startDate={event.startDate}
                    quantity={event.quantity}
                    key={event.eventId}
                  />
                ))}
                <p className="text-slate-900 w-full font-bold">
                  TOTAL:{" "}
                  <span className="float-right">
                    {formatter(paymentIntent.amount / 100)}
                  </span>
                </p>
                <p className="text-slate-900 w-full text-xs pt-4 border-t border-slate-300">
                  You will also receive a confirmation email with your receipt.
                </p>
                <p className="text-slate-400 w-full text-xs text-center font-light">
                  ID: {paymentIntent.purchaseId}
                </p>
              </>
            )}
          </div>
          {!loading && (
            <button
              className="bg-black text-white px-5 py-3 uppercase text-xs font-semibold"
              onClick={() =>
                (window.location.href = "https://augustamuseum.org")
              }
            >
              Back to Augusta Museum of History
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const EventBlock = (props) => {
  const formatter = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price);
  };

  return (
    <div className="w-full flex flex-row justify-between items-center border-b border-slate-300 py-2">
      <div className="flex flex-col justify-center items-start gap-1">
        <h1 className="text-sm font-body font-medium uppercase">
          Time Detectives | {props.quantity}x
        </h1>
        <p className="text-xs">
          {dayjs(props.startDate).format("MMM D YYYY[, at] h A")}
        </p>
      </div>
      <p>{formatter(props.quantity * 25)}</p>
    </div>
  );
};

export default Success;
