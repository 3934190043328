import { AddFour } from "@icon-park/react";
import dayjs from "dayjs";

const ShowingButton = (props) => {
  const tryAdding = () => {
    if (props.available > 0) {
      props.addToCart({
        startDate: props.startDate,
        price: 25,
        quantity: 1,
        eventId: props.eventId,
      });
    }
  };
  return (
    <div
      className={
        `w-full h-16 flex flex-row justify-between items-center border` +
        (props.available === 0
          ? " border-gray-300 text-gray-400"
          : " border-black")
      }
    >
      <div className="h-full flex flex-1 flex-col justify-center items-center">
        <p className={`text-sm font-body font-medium uppercase`}>
          {dayjs(props.startDate).format("MMM D YYYY[, at] h A")}
        </p>
        {props.available === 0 ? (
          <p className="text-xs font-medium text-gray-400">SOLD OUT</p>
        ) : null}
        {props.available < 11 && props.available >= 1 ? (
          <p className="text-xs font-medium">ONLY 10 LEFT</p>
        ) : null}
      </div>
      <div
        className={`h-full w-16 border-l flex flex-col justify-center items-center text-gray-500 transition-all ease-in-out duration-150
            ${
              props.available > 0
                ? "border-black hover:text-black cursor-pointer"
                : "border-gray-200 cursor-not-allowed text-gray-300"
            }`}
        onClick={() => tryAdding()}
      >
        <AddFour size={24} />
      </div>
    </div>
  );
};

export default ShowingButton;
