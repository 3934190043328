import { Fragment, useState, useEffect, useRef } from "react";
import { ReactComponent as Logo } from "./assets/logo-white.svg";
import { GetEvents, CreatePaymentIntent } from "./actions/events";
import ShowingButton from "./components/showingButton";
import dayjs from "dayjs";
import * as _ from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import toast, { Toaster } from "react-hot-toast";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/24/outline";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(
  "pk_live_51LKr2sJEgIM9h51abUsmznFqn1u9w13WL1WNCPmYTsPpETKtQBrRC5kRw9cIgevdEScvBdakbw8BrSU4mM15xSXp00jO3sFxKc"
);

const App = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [cart, setCart] = useState([]);
  const [open, setOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [memberPrice, setMemberPrice] = useState(false);
  const [wildCode, setWildCode] = useState(false);
  const [cartModal, setCartModal] = useState(false);

  const codeInput = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetEvents()
        .then((res) => {
          let tmp = res.data;
          let toSet = [];
          tmp.forEach((event) => {
            if (dayjs().isBefore(dayjs(event.startDate))) {
              toSet.push(event);
            }
          });
          setEvents(toSet);
        })
        .catch((err) => {
          setEvents([]);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  let addToCart = (event) => {
    setLoading(true);
    let tmp = cart;
    let index = _.findIndex(tmp, (o) => o.eventId === event.eventId);
    let available = _.findIndex(events, (o) => o.eventId === event.eventId);
    toast.success("Item added to cart", {
      duration: 1500,
    });
    if (index !== -1) {
      if (events[available].available - tmp[index].quantity === 0) {
        toast.error("Sorry but this quantity of tickets is unavailable");
        setLoading(false);
        return;
      } else {
        tmp[index].quantity += 1;
      }
    } else {
      if (events[available].available === 0) {
        toast.error("Sorry but this quantity of tickets is unavailable");
        setLoading(false);
        return;
      } else {
        tmp.push(event);
      }
    }
    setCart(tmp);
    setTimeout(() => {
      setLoading(false);
    }, 700);
  };

  const formatter = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price);
  };

  let getTotal = () => {
    let total = 0;
    cart.forEach((item) => {
      if (!memberPrice && !wildCode) {
        total += 25 * item.quantity;
      } else if (wildCode) {
        total = 0;
      } else {
        total += 20 * item.quantity;
      }
    });
    return total;
  };

  let getDiscount = () => {
    let total = 0;
    cart.forEach((item) => {
      if (memberPrice && !wildCode) {
        total += 5 * item.quantity;
      } else if (wildCode) {
        total = 25 * item.quantity;
      }
    });
    return total;
  };

  const openInfo = () => {
    if (cart.length > 0) {
      setInfoOpen(true);
    } else {
      toast.error("Please add at least one item to your cart");
    }
  };

  const updateValue = (e) => {
    let { name, value } = e.target;
    let tmp = values;
    tmp[name] = value;
    setValues(tmp);
  };

  const updatePhone = (phn) => {
    let tmp = values;
    tmp.phoneNumber = phn.slice(1);
    console.log(tmp);
    setValues(tmp);
  };

  const submitInfo = () => {
    let error = false;
    if (values.firstName === "") {
      error = true;
      toast.error("Please provide your first name");
    } else if (!error && values.lastName === "") {
      error = true;
      toast.error("Please provide your last name");
    } else if (!error && values.email === "") {
      error = true;
      toast.error("Please provide your email");
    } else if (
      (!error && values.phoneNumber === "") ||
      values.phoneNumber.length !== 10
    ) {
      error = true;
      toast.error("Please provide a valid phone number");
    }
    if (!error) {
      let toSend = {
        events: [],
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phoneNumber,
        amount: parseInt(getTotal() * 100),
      };
      if (wildCode) {
        toSend.amount = 0;
      }
      cart.forEach((item) => {
        toSend.events.push({ eventId: item.eventId, quantity: item.quantity });
      });
      CreatePaymentIntent(toSend)
        .then((res) => {
          if (toSend.amount === 0) {
            let pi_id = res.data.paymentIntent;
            toast.success("Tickets acquired successfully!");
            setTimeout(() => {
              navigate(`/success?payment_intent=${pi_id}`);
            }, 1300);
          } else {
            setClientSecret(res.data.client_secret);
            setTimeout(() => {
              setInfoOpen(false);
              setOpen(true);
            }, 700);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong, please try again.");
        });
    }
  };

  const removeCartItem = (item) => {
    setLoading(true);
    let tmp = cart;
    let index = _.findIndex(tmp, (o) => o.eventId === item.eventId);
    if (index !== -1) {
      tmp[index].quantity -= 1;
      if (tmp[index].quantity === 0) {
        tmp.splice(index, 1);
      }
    }
    setCart(tmp);
    setTimeout(() => {
      setLoading(false);
    }, 700);
  };

  const addCartItem = (item) => {
    setLoading(true);
    let tmp = cart;
    let index = _.findIndex(tmp, (o) => o.eventId === item.eventId);
    let available = _.findIndex(events, (o) => o.eventId === item.eventId);
    if (index !== -1) {
      if (events[available].available - tmp[index].quantity === 0) {
        toast.error("Sorry but this quantity of tickets is unavailable");
        setLoading(false);
        return;
      } else {
        tmp[index].quantity += 1;
      }
    }
    setCart(tmp);
    setTimeout(() => {
      setLoading(false);
    }, 700);
  };

  const applyPromo = () => {
    let code = codeInput.current.value;
    code = code.toUpperCase();
    code = code.replace(" ", "");
    if (code === "MEMBER5") {
      toast.success("Promo code valid!");
      setMemberPrice(true);
    } else if (code === "SAV1NGH1ST0RY") {
      toast.success("Promo code valid!");
      setWildCode(true);
    } else {
      toast.error("Invalid promo code");
      codeInput.current.value = "";
      setMemberPrice(false);
    }
  };

  const getQuantity = () => {
    let total = 0;
    cart.forEach((item) => {
      total += item.quantity;
    });
    return total;
  };

  const openCart = () => {
    if (cart.length === 0) {
      toast.error("Please add at least one item to your cart");
    } else {
      setCartModal(true);
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-start overflow-x-hidden">
      <Toaster
        position="top-right"
        toastOptions={{
          style: { borderRadius: 0 },
        }}
      />
      <div className="w-full h-14 bg-black text-white flex flex-row justify-center items-center">
        <div className="w-full h-14 flex flex-row justify-between items-center max-w-[1440px] px-2 lg:px-0">
          <div
            className="flex flex-row justify-start items-center h-full gap-1 lg:gap-3 cursor-pointer"
            onClick={() => (window.location.href = "https://augustamuseum.org")}
          >
            <Logo className="h-6 lg:h-3/5 w-auto" />
            <h1 className="font-serif font-light text-sm lg:text-[18px]">
              Augusta Museum of History
            </h1>
          </div>
          <div className="flex flex-row justify-end items-center gap-3 uppercase font-body font-semibold text-sm">
            <h1
              className="text-white hover:text-blue-700 transition-all duration-150 ease-in-out cursor-pointer"
              onClick={() =>
                (window.location.href = "https://augustamuseum.org")
              }
            >
              Home
            </h1>
            <button onClick={() => openCart()} className="lg:hidden">
              CART
              {cart.length > 0 && (
                <span className="ml-2 bg-white text-black rounded-full py-1 px-2">
                  {getQuantity()}
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-1 py-16 flex-col justify-center items-center lg:h-full lg:pb-0 pb-20">
        <div className="w-full h-full max-w-[1440px] flex flex-col lg:flex-row justify-between items-start gap-5 px-2 lg:px-0">
          <div className="w-full lg:w-3/4 h-full flex flex-col justify-start items-start sticky">
            <h2 className="text-4xl font-bold font-serif">Time Detectives</h2>
            <p className="text-sm">Augusta Museum of History</p>
            <div className="w-full my-4 border-t border-gray-200 mr-4" />
            <p className="text-xs font-body uppercase font-semibold text-gray-300">
              Secure your tickets now!
            </p>
            <div className="w-full h-full grid grid-cols-1 lg:grid-cols-2 mt-5 gap-5 gap-y-2 lg:gap-y-6 content-start">
              {events.map((event) => {
                return (
                  <ShowingButton
                    available={event.available}
                    key={event.eventId}
                    eventId={event.eventId}
                    startDate={event.startDate}
                    addToCart={(e) => addToCart(e)}
                  />
                );
              })}
            </div>
          </div>
          <div
            className="w-full lg:w-1/4 lg:flex flex-col justify-start items-start p-5 border border-gray-200 hidden"
            id="cart"
          >
            <p className="uppercase font-extrabold text-2xl text-gray-400">
              Your Cart
            </p>
            <div className="w-full border-t border-gray-200 my-2" />
            {cart.length > 0 ? (
              <div className="w-full h-fit max-h-[60vh] overflow-y-auto flex flex-col justify-start items-center">
                {cart.length > 0 ? (
                  <div className="w-full flex flex-row justify-between items-center text-sm text-gray-400 mb-2 font-semibold">
                    <p className="w-1/2 text-left">ITEM</p>
                    <p className="w-1/4 text-right">QTY</p>
                    <p className="w-1/4 text-right">PRICE</p>
                  </div>
                ) : null}
                {cart.map((item) => {
                  return (
                    <div className="w-full flex flex-row justify-between items-center">
                      <p className="text-sm text-gray-400 w-1/2">
                        {dayjs(item.startDate).format("MMM D YYYY[, at] h A")}
                      </p>
                      <div className="flex flex-row justify-between items-center gap-2 w-1/4 text-gray-500">
                        <MinusCircleIcon
                          className="w-5 h-5 cursor-pointer"
                          onClick={() => removeCartItem(item)}
                        />
                        <p className="text-sm text-gray-400 text-center">
                          {item.quantity}
                        </p>
                        <PlusCircleIcon
                          className="w-5 h-5 cursor-pointer"
                          onClick={() => addCartItem(item)}
                        />
                      </div>
                      <p className="text-sm text-gray-400 w-1/4 text-right">
                        {formatter(item.price * item.quantity)}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="text-sm text-gray-400">No items added yet</p>
            )}
            {(cart.length > 0 && memberPrice) ||
            (cart.length > 0 && wildCode) ? (
              <p className="w-full mt-3 border-t border-gray-200 pt-2 font-medium">
                DISCOUNT:{" "}
                <span className="float-right">{formatter(getDiscount())}</span>
              </p>
            ) : null}
            {cart.length > 0 ? (
              <p className="w-full mt-3 border-t border-gray-200 pt-2 font-semibold">
                TOTAL:{" "}
                <span className="float-right">{formatter(getTotal())}</span>
              </p>
            ) : null}
            {cart.length > 0 ? (
              <div className="my-1 flex flex-row justify-start items-center w-full gap-2">
                <input
                  className="w-3/4 px-4 py-2 border border-gray-300 my-2 outline-none"
                  placeholder="Promo Code"
                  ref={codeInput}
                />
                <button
                  className="w-1/4 py-2 bg-black text-white font-bold tracking-wider my-3 border border-black"
                  onClick={() => applyPromo()}
                >
                  APPLY
                </button>
              </div>
            ) : null}
            <div className="w-full border-t border-gray-200 my-2" />
            <button
              className="w-full py-3 bg-black text-white font-bold tracking-wider my-3"
              onClick={() => openInfo()}
            >
              {loading ? "PROCESSING" : "CHECKOUT"}
            </button>
            <p className="text-xs text-gray-400 text-center w-full">
              By purchasing from this site, you agree
              <br />
              with our{" "}
              <a
                href="/terms"
                target="_blank"
                className="text-gray-600 underline underline-offset-2 hover:text-black transition-all duration-150 ease-in-out"
              >
                Terms & Conditions
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      {!cartModal && !open && !infoOpen ? (
        <div className="w-full h-20 border-t bg-white border-gray-200 fixed bottom-0 left-0 z-50 flex flex-row justify-center items-center lg:hidden">
          <button
            className="w-full mx-4 py-3 bg-black text-white font-bold tracking-wider"
            onClick={() => openCart()}
          >
            {loading ? "PROCESSING" : "CHECKOUT"}
          </button>
        </div>
      ) : null}
      <Transition.Root show={cartModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setCartModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden bg-white px-4 pt-5 pb-4 text-left transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div>
                    <div className="flex flex-row justify-between items-center text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        CART
                      </Dialog.Title>
                      <button
                        className="text-sm text-white bg-black px-2 py-1"
                        onClick={() => setCartModal(false)}
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex flex-col">
                    {cart.length > 0 ? (
                      <div className="w-full h-fit max-h-[60vh] overflow-y-auto flex flex-col justify-start items-center">
                        {cart.length > 0 ? (
                          <div className="w-full flex flex-row justify-between items-center text-sm text-gray-400 mb-2 font-semibold">
                            <p className="w-1/2 text-left">ITEM</p>
                            <p className="w-1/4 text-right">QTY</p>
                            <p className="w-1/4 text-right">PRICE</p>
                          </div>
                        ) : null}
                        {cart.map((item) => {
                          return (
                            <div className="w-full flex flex-row justify-between items-center">
                              <p className="text-sm text-gray-400 w-1/2">
                                {dayjs(item.startDate).format(
                                  "MMM D YYYY[, at] h A"
                                )}
                              </p>
                              <div className="flex flex-row justify-between items-center gap-2 w-1/4 text-gray-500">
                                <MinusCircleIcon
                                  className="w-5 h-5 cursor-pointer"
                                  onClick={() => removeCartItem(item)}
                                />
                                <p className="text-sm text-gray-400 text-center">
                                  {item.quantity}
                                </p>
                                <PlusCircleIcon
                                  className="w-5 h-5 cursor-pointer"
                                  onClick={() => addCartItem(item)}
                                />
                              </div>
                              <p className="text-sm text-gray-400 w-1/4 text-right">
                                {formatter(item.price * item.quantity)}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <p className="text-sm text-gray-400">
                        No items added yet
                      </p>
                    )}
                    {(cart.length > 0 && memberPrice) ||
                    (cart.length > 0 && wildCode) ? (
                      <p className="w-full mt-3 border-t border-gray-200 pt-2 font-medium">
                        DISCOUNT:{" "}
                        <span className="float-right">
                          {formatter(getDiscount())}
                        </span>
                      </p>
                    ) : null}
                    {cart.length > 0 ? (
                      <p className="w-full mt-3 border-t border-gray-200 pt-2 font-semibold">
                        TOTAL:{" "}
                        <span className="float-right">
                          {formatter(getTotal())}
                        </span>
                      </p>
                    ) : null}
                    {cart.length > 0 ? (
                      <div className="my-1 flex flex-row justify-start items-center w-full gap-2">
                        <input
                          className="w-3/4 px-4 py-2 border border-gray-300 my-2 outline-none"
                          placeholder="Promo Code"
                          ref={codeInput}
                        />
                        <button
                          className="w-1/4 py-2 bg-black text-white font-bold tracking-wider my-3 border border-black"
                          onClick={() => applyPromo()}
                        >
                          APPLY
                        </button>
                      </div>
                    ) : null}
                    <div className="w-full border-t border-gray-200 my-2" />
                    <button
                      className="w-full py-3 bg-black text-white font-bold tracking-wider my-3"
                      onClick={() => openInfo()}
                    >
                      {loading ? "PROCESSING" : "CHECKOUT"}
                    </button>
                    <p className="text-xs text-gray-400 text-center w-full">
                      By purchasing from this site, you agree
                      <br />
                      with our{" "}
                      <a
                        href="/terms"
                        target="_blank"
                        className="text-gray-600 underline underline-offset-2 hover:text-black transition-all duration-150 ease-in-out"
                      >
                        Terms & Conditions
                      </a>
                      .
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden bg-white px-4 pt-5 pb-4 text-left transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div>
                    <div className="text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        CHECKOUT
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Please provide the following information to complete
                          your purchase.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    {stripePromise && clientSecret && (
                      <Elements
                        stripe={stripePromise}
                        options={{ clientSecret }}
                      >
                        <CheckoutForm />
                      </Elements>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={infoOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setInfoOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden bg-white px-4 pt-5 pb-4 text-left transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div>
                    <div className="text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        CHECKOUT
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Please provide the following information to complete
                          your purchase.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      First Name
                    </label>
                    <div className="my-2">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm outline-none focus:outline-1 focus:outline-slate-500 placeholder:text-gray-400 sm:text-sm sm:leading-6 px-2"
                        placeholder="First Name"
                        onChange={(e) => updateValue(e)}
                      />
                    </div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Last Name
                    </label>
                    <div className="my-2">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm outline-none focus:outline-1 focus:outline-slate-500 placeholder:text-gray-400 sm:text-sm sm:leading-6 px-2"
                        placeholder="Last Name"
                        onChange={(e) => updateValue(e)}
                      />
                    </div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <div className="my-2">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm outline-none focus:outline-1 focus:outline-slate-500 placeholder:text-gray-400 sm:text-sm sm:leading-6 px-2"
                        placeholder="john.doe@example.com"
                        onChange={(e) => updateValue(e)}
                      />
                    </div>
                    <label
                      htmlFor="phoneNumber"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone Number
                    </label>
                    {/* <div className="my-2">
                      <input
                        type="tel"
                        name="phoneNumber"
                        id="phoneNumber"
                        maxLength={10}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm outline-none focus:outline-1 focus:outline-slate-500 placeholder:text-gray-400 sm:text-sm sm:leading-6 px-2"
                        placeholder="Your Phone Number"
                        onChange={(e) => updateValue(e)}
                      />
                    </div> */}
                    <div className="my-2">
                      <PhoneInput
                        disableDropdown
                        // disableCountryCode
                        country="us"
                        onChange={(phone) => updatePhone(phone)}
                      />
                    </div>
                    <button
                      className="inline-flex w-full justify-center bg-black px-3 py-2 text-sm font-semibold text-white outline-none focus-transparent mt-5"
                      onClick={() => submitInfo()}
                    >
                      NEXT
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!elements || !stripe) {
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/success`,
      },
      redirect: "always",
    });

    if (error) {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setMessage("Payment status: " + paymentIntent.status);
    } else {
      setMessage("Something went wrong, please try again.");
    }
    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        type="submit"
        className="inline-flex w-full justify-center bg-black px-3 py-2 text-sm font-semibold text-white outline-none focus-transparent mt-5"
        disabled={!stripe || !elements || isProcessing}
      >
        {isProcessing ? "PROCESSING" : "COMPLETE PURCHASE"}
      </button>
      <p className="my-3 w-full text-center">{message}</p>
    </form>
  );
};

export default App;
